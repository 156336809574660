<template>
  <div class="homepage">
    <div class="content">
      <!-- 轮播用户学习信息 -->
      <div class="banner">
        <div class="banner-left">
          <template v-if="bannerList && bannerList.length > 1">
            <el-carousel trigger="click" :autoplay="true" height="310px">
              <el-carousel-item v-for="item in bannerList" :key="item.id + 'banner'">
                <img :src="item.picUrl" @click="handleBanner(item)" class="picUrl" alt="">
              </el-carousel-item>
            </el-carousel>
          </template>
          <template v-else>
            <img :src="bannerList[0]?.picUrl" @click="handleBanner(bannerList[0])" class="picUrl" alt="">
          </template>
        </div>
        <div class="user-right">
          <template v-if="token">
            <div class="user-info">
              <img :src="subUserInfo?.headImage ? subUserInfo.headImage : require('../../../assets/images/userL.png')"
                class="headImg" alt="" @click="goPersonal">
              <div class="right">
                <p class="label">{{ getTimeState() }}</p>
                <p class="name" @click="goPersonal">{{ subUserInfo?.name }}</p>
              </div>
            </div>
            <div class="learning-box">
              <div class="xueshi">
                <p class="num" v-if="totalLearnTime">{{  (totalLearnTime / 3600) >= 1 ? (totalLearnTime / 3600).toFixed(2) : (totalLearnTime / 60).toFixed(2)}}</p>
                <p class="num" v-else>0</p>
                <p class="label">累计<br />完成学时({{ totalLearnTime / 3600 >= 1 ? 'h' : 'min'  }})</p>
              </div>
              <div class="xuefen">
                <p class="num">{{ studyStatistics?.credit || 0 }}</p>
                <p class="label">累计<br />学分</p>
              </div>
              <div class="kecheng">
                <p class="num">{{ studyStatistics?.finishCourseCount || 0 }}</p>
                <p class="label">累计<br />完成课程</p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="not-log">
              <!-- <img src="" class="logo" alt=""> -->
              <h1>尖椒Saas</h1>
              <p class="tip">海量优质课程</p>
              <div class="loginBtn" @click="$router.push('/login')">
                <p>立即登录</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- 最近学习 -->
      <div class="recentStudy" v-if="token">
        <div class="titleBox">
          <img class="titleImg" src="../../../assets/images/home/recentStudy-title.png" alt="">
          <div class="gengduo" @click="$router.push('/personal')">
            <span class="txt">更多</span>
            <img src="../../../assets/images/home/gengduo.png" alt="">
          </div>
        </div>
        <div class="itemBox" v-if="recentStudyList.length">
          <!-- <div class="tipBox">
              <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/home/recentStudy-img.png" alt="">
              <p class="tipText">您已经30天没学习啦！学习中心有XX个课程等着你学习哦！</p>
            </div> -->
          <div class="item" v-for="item in recentStudyList" @click="jumpLearnDetail(item)" :key="item.id + 'recentStudy'">
            <div class="title">
              <img src="../../../assets/images/home/course-icon.png" class="icon" alt="">
              <p class="name" :title="item.name">{{ item.name }}</p>
            </div>
            <div class="info">
              <p>学&nbsp;&nbsp;&nbsp;分：{{ item.credit }}</p>
              <p>学&nbsp;&nbsp;&nbsp;时：
                <template v-if="item.resLength > 0">
                  {{ (item.resLength / 3600) >= 1 ?(item.resLength / 3600).toFixed(2) : (item.resLength / 60).toFixed(2) }}{{ (item.resLength / 3600) >= 1 ? 'h' :'min' }}
                </template>
                <template v-else>0</template>
              </p>
              <p>已学习：
                <template v-if="item.resLength > 0">
                  {{ (item.learnTotalTime / 3600)>=1?(item.learnTotalTime / 3600).toFixed(2):(item.learnTotalTime / 60).toFixed(2) }}{{ (item.learnTotalTime / 3600) >= 1 ? 'h' :'min' }}
                </template>
                <template v-else>0</template>
              </p>
            </div>
            <div class="progress">
              <span>完成</span>
              <el-progress color="$WDPrimaryColor" :percentage="item.studyProcess || 0"></el-progress>
            </div>
          </div>
        </div>
        <div class="no-content" v-else>
          <div class="left">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/home/xueixi-empty.png" alt="">
          </div>
          <div class="right">
            <p class="tip">别人已经开始学习啦！<br />你也快来加入进来吧！</p>
            <div class="btn" @click="$router.push('/course/list')">
              <p>找课程</p>
            </div>
          </div>
        </div>

      </div>
      <!-- 推荐课程 -->
      <div class="recommendCourse">
        <div class="titleBox">
          <img class="titleImg" src="../../../assets/images/home/course-title1.png" alt="">
          <div class="gengduo" @click="$router.push('/course/list')">
            <span class="txt">更多</span>
            <img src="../../../assets/images/home/gengduo.png" alt="">
          </div>
        </div>
        <div class="itemBox" v-if="newCourseList.length">
          <div class="item" v-for="item in newCourseList" @click="jumpCourseCenterDetail(item)"
            :key="item.id + 'newCourse'">
            <img :src="item.picUrl" class="cover" alt="">
            <div class="bottom">
              <p class="title" :title="item.name">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="no-content" v-else>
          <div class="left">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/home/xueixi-empty.png" alt="">
          </div>
          <div class="right">
            <p class="tip">暂无内容<br />去课程中心查看更多吧！</p>
            <div class="btn" @click="$router.push('/course/list')">
              <p>找课程</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 最新课程 -->
      <div class="newCourse" v-if="!token">
        <div class="titleBox">
          <img class="titleImg" src="../../../assets/images/home/course-title.png" alt="">
          <!-- <div class="tabs">
              <p class="tabItem" v-for="item in newCourseTabs" :key="item.label+'newCourseTab'">{{ item.value }}</p>
            </div> -->
          <div class="gengduo" @click="$router.push('/login')">
            <span class="txt">更多</span>
            <img src="../../../assets/images/home/gengduo.png" alt="">
          </div>
        </div>
        <div class="itemBox">
          <div class="item" v-for="item in newCourseList" @click="jumpCourseCenterDetail(item)"
            :key="item.id + 'newCourse'">
            <img :src="item.picUrl" class="cover" alt="">
            <div class="bottom">
              <p class="title" :title="item.name">{{ item.name }}</p>
            </div>
          </div>
        </div>

      </div>
      <!-- 资讯 -->
      <div class="information" v-if="token">
        <div class="left">
          <div class="titleBox">
            <img class="titleImg" src="../../../assets/images/home/zixun-title.png" alt="">
            <div class="gengduo" @click="$router.push('/information/list')">
              <span class="txt">更多</span>
              <img src="../../../assets/images/home/gengduo.png" alt="">
            </div>
          </div>
          <div class="informationList">
            <div class="item" v-for="item in informationList" @click="jumpInformationDetail(item)"
              :key="item.id + 'information'">
              <div class="item-left">
                <img :src="item.picUrl" class="cover" alt="">
              </div>
              <div class="item-right">
                <p class="title">{{ item.name }}</p>
                <div class="bottom">
                  <p class="num">
                    <!-- <span class="circle"></span> -->
                    <!-- <span class="txt">{{ item.hitsCount || 0 }}</span> -->
                  </p>
                  <p class="time">{{ item.publishTime }}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="titleBox" style="width: 88px;">
            <img class="titleImg" src="../../../assets/images/home/paihang-title.png" alt="">
          </div>
          <div class="rankingList">
            <div class="rankingTabs">
              <p :class="{ 'active': rankingTabsIndex == index }" @click="rankingTabsClick(index, item)" class="txt"
                v-for="(item, index) in rankingTabs" :key="item.label + 'rankingTabs'">
                {{ item.value }}
                <span class="line" v-if="rankingTabsIndex == index"></span>
              </p>
            </div>
            <template v-if="CreditRankingList.length">
              <div class="item" v-for="(item, index) in CreditRankingList" :key="index">
                <div class="ranking">
                  <img src="../../../assets/images/home/1.png" v-if="index + 1 === 1" alt="">
                  <img src="../../../assets/images/home/2.png" v-else-if="index + 1 === 2" alt="">
                  <img src="../../../assets/images/home/3.png" v-else-if="index + 1 === 3" alt="">
                  <p v-else>{{ index + 1 }}</p>
                </div>
                <img :src="item.headImage ? item.headImage : require('../../../assets/images/userL.png')" class="headImg"
                  alt="">
                <p class="userName">{{ item.name }}</p>
                <!-- <p class="taskName">{{ item.taskName }}</p> -->
                <p class="score">{{ rankingTabsIndex === 1 ?formatSeconds(item.value)  : item.value }}</p>
              </div>
            </template>
            <template v-else>
              <div class="no-content">
                <p class="tip">榜上无人，奋勇争先</p>
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/home/paihang-empty.png" alt="">
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>
    <changePasswordDialog :dialogVisible="dialogVisible"></changePasswordDialog>
  </div>
</template>

<script>
import changePasswordDialog from '../components/changePasswordDialog.vue';

export default {
  components: { changePasswordDialog },
  computed: {
    subUserInfo() {
      return this.$store.state.subUserInfo
    }
  },
  data() {
    return {
      isSettingPasswordHomeDialog: false,
      dialogVisible: {
        show: false,
      },
      token: '',
      rankingTabsIndex: 0,
      bannerList: [],
      rankingTabs: [
        {
          label: 2,
          value: '学分'
        },
        {
          label: 1,
          value: '学时'
        },
        {
          label: 3,
          value: '课程'
        }
      ],
      // 学分排行
      CreditRankingList: [],
      informationList: [], // 资讯列表
      newCourseTabs: [
        {
          label: 1,
          value: '最新',
        },
        {
          label: 2,
          value: '最热',
        },
        {
          label: 3,
          value: '精品',
        }
      ],
      newCourseList: [],
      recentStudyList: [],
      studyStatistics: {},
      totalLearnTime:0,
    }
  },
  created() {
    this.token = localStorage.getItem('token');
    this.getHomeData(1);
    this.getHomeData(3, 4);
    if (this.token) {
      if(sessionStorage.getItem('isSettingPasswordHomeDialog') != 'true'){
        this.alreadySetPassword()
      }
      this.getMyTaskList();
      this.getHomeData(2, 20);
      this.getUserTopCount(2)
      this.myStudyStatistics();
      this.getAllLearnedData()
    }
  },
  mounted() {
  },
  methods: {
    // 获取学习时长
    getAllLearnedData() {
      this.$api.learn.getAllLearnedData().then((res) => {
        this.totalLearnTime = res.data.totalLearnTime || 0;
      });
    },
    // 获取是否设置过密码，绑定弹窗
    async alreadySetPassword() {
     const res = await this.$api.usercenter.alreadySetPassword({});
     this.isSettingPasswordHomeDialog = res.data
     sessionStorage.setItem('isSettingPasswordHomeDialog',res.data)
     if (!this.isSettingPasswordHomeDialog) {
        this.dialogVisible.show = true;
      } else {
        this.dialogVisible.show = false;
      }
  
    },
    formatSeconds(value) {
      let result = parseInt(value);
      let h =
        Math.floor((result / 3600) % 24) < 10
          ?  Math.floor((result / 3600) % 24)
          : Math.floor((result / 3600) % 24);
 
      let m =
        Math.floor((result / 60) % 60) < 10
          ?  Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
 
      let s =
        Math.floor(result % 60) < 10
          ?  Math.floor(result % 60)
          : Math.floor(result % 60);
 
      let res = "";
      if (h !== 0) res += `${h}h`;
 
      if (m !== 0) res += `${m}min`;
 
      if (s !== 0) res += `${s}s`;
 
      return res;
    },
    goPersonal() {
      this.$router.push({
        path: '/personal'
      })
    },
    // 课程中心详情
    jumpCourseCenterDetail(item) {
      this.$router.push({
        path: '/course/detail',
        query: {
          ucode: item.objUcode,
        }
      })
    },
    // 学习中心详情
    jumpLearnDetail(item) {
      if(item.displayStyle === 1){
        let path ='/learn/course/detail';
        if(item.type==3){
          path='/learn/task/detail'
        }
        this.$router.push({
          path: path,
          query: {
            id: item.id,
            type:item.type
          }
        })
      }else{
        this.$router.push({
          path: "/learn/StageMap",
          query: {
            id: item.id,
          },
        });
      }
      
    },
    // 资讯详情
    jumpInformationDetail(item) {
      this.$router.push({
        path: '/information/detail',
        query: {
          id: item.objId
        }
      })
    },
    // banner 跳转
    handleBanner(item) {
      console.log(item, 'reim=====')
      // 跳转对象类型 objType 1：文章 2：课程
      if (item.isJump) {
        if (item.objType === 1) {
          this.jumpInformationDetail(item)
        } else {
          this.jumpCourseCenterDetail(item)
        }
      }
    },
    myStudyStatistics() {
      const params = {
        hasCredit:true,//是否包括学分
        hasFinishCourseCount:true,//是否包含课程完成数量
      }
      this.$api.home.myStudyStatistics(params).then(res => {
        this.studyStatistics = res.data
      })
    },
    // 排行榜
    getUserTopCount(dataType) {
      const params = {
        pageNum: 1,
        pageSize: 10
      }
      const data = {
        dataType // 1:学时 2:学分 3:课程学习（学习完成数）
      }
      this.$api.home.getUserTopCount({ params, data }).then(res => {
        this.CreditRankingList = res.data?.dataList || []
      })
    },
    // 最近学习
    getMyTaskList() {
      const params = {
        pageNum: 1,
        pageSize: 4,
        status: 1
      }
      this.$api.learn.getMyTaskList({ params }).then(res => {
        this.recentStudyList = res.data?.list || []
      })
    },
    getHomeData(type, pageSize) {
      const params = {
        pageNum: 1,
        pageSize: pageSize || 10,
      }
      const data = {
        clientType: 1,
        dataType: type,//数据类型 1：轮番图 2：课程推荐 3：资讯
      }
      this.$api.home.getHomeData({ data, params }).then(res => {
        if (res.data) {
          if (type === 1) {
            this.bannerList = res.data.list || [];
          } else if (type === 2) {
            this.newCourseList = res.data.list || [];
          } else if (type === 3) {
            this.informationList = res.data.list || [];
          }
        }

        console.log(res, 'res===')
      })
    },
    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      let minute = timeNow.getMinutes();
      // 设置默认文字
      let state = ``;
      console.log(hours, minute, 'hours====')
      // 判断当前时间段
      if (hours >= 0 && hours < 8) {
        state = `早上好!`;
      } else if (hours >= 8 && hours < 11) {
        state = `上午好!`;
      } else if (hours >= 11 && hours < 13) {
        state = `中午好!`;
      } else if (hours >= 13 && hours < 17) {
        state = `下午好!`;
      } else if (hours >= 17 && hours < 24) {
        state = `晚上好!`;
      }
      return state;
    },
    // 排行榜 tab切换
    rankingTabsClick(index, item) {
      this.rankingTabsIndex = index;
      this.getUserTopCount(item.label)
      console.log(item, 'item')
    }
  }
}
</script>

<style lang="scss" scoped src="../asset/css/index.scss">
</style>